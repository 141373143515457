import React from 'react'
import DefaultTemplate from '../components/Layouts/DefaultTemplate'
import requester from '../utils/requester'
import { ReaderAPI } from '../repository'
import utils from '../utils'
const serverSideUtils = utils.serverSideUtils

export default function Home({ account, defaultPage }) {
  return account ? (
    <DefaultTemplate page={defaultPage} />
  ) : (
    <main>
      <p data-cy="error-no-campaign-provided">
        An active campaign name must be provided
      </p>
    </main>
  )
}

const showDefaultPage = async (account) => {
  const [pageByIdData, pageByIdError] = await requester([
    ReaderAPI.getPage({
      domain: '',
      pageId: account.defaultPageId,
    }),
  ])
  const [pageById] = pageByIdData
  return {
    account,
    defaultPage: pageById?.value?.data,
    errors: pageByIdError,
  }
}

const redirectToDefaultCampaign = async (ctx, account) => {
  const campaignById = await ReaderAPI.getCampaign({
    domain: '',
    campaignName: account.defaultCampaignId,
  })
  await serverSideUtils.redirectToCampaign(ctx, campaignById?.data)
}

const getAccountBySubdomain = async (subdomain) => {
  const accounts = await ReaderAPI.getAccounts({
    domain: '',
    options: {
      params: { subdomain, size: 1, isEnabled: true },
    },
  })
  return accounts?.data[0]
}

const getAccount = async (subdomain, customUrl) => {
  if (customUrl) {
    return serverSideUtils.getAccountByCustomUrl(customUrl)
  }
  return subdomain && getAccountBySubdomain(subdomain)
}

export const getServerSideProps = async (ctx) => {
  const { accountSubdomain, customUrl } = utils.parseReaderUrl(ctx)

  const account = await getAccount(accountSubdomain, customUrl)

  if (!account) {
    return { props: {} }
  }

  if (account.useDefaultPage) {
    return { props: await showDefaultPage(account) }
  } else {
    await redirectToDefaultCampaign(ctx, account)
    return { props: {} }
  }
}
